import React from 'react';

import { Link } from 'react-router-dom';
import tasksPageImage from "../assets/images/tasksPage.png"; // Assurez-vous que l'image est disponible
import NoticeComponent from '../NoticeComponent/NoticeComponent';

//data index 4
const TaskspageNotice = () => {
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, { title: 'Notice des Tâches', url: '/tasks-notice' }]}
            title="Notice de la Page 'Tâches'"
            objective="Fournir un aperçu des fonctionnalités de la page Tâches, incluant la recherche et la navigation vers des affaires spécifiques."
            considerations={[
                "Les utilisateurs peuvent filtrer les tâches par affaire via un champ de recherche.",
                "Chaque liste de tâches est liée à une affaire spécifique, avec un accès rapide au dashboard de l'affaire."
            ]}
            screenDescription="La page 'Tâches' permet aux utilisateurs de visualiser et de gérer les tâches à faire associées à différentes affaires. Elle est équipée d'un champ de recherche pour filtrer les tâches et d'un accès direct aux dashboards des affaires."
            steps={[
                "Utiliser le champ de recherche pour filtrer les tâches par nom d'affaire.",
                "Visualiser la liste des tâches pour chaque affaire affichée.",
                // link to affaire dashboard
                { content: <span>Cliquer sur 'Aller à l'affaire' pour accéder au <Link to={`/affaireId`}>Dashboard de l'affaire</Link> et gérer les détails spécifiques de l'affaire.</span> },
                //link to task detail notice
                { content: <span>Cliquer sur une tâche pour voir les détails et les options de gestion. Pour plus d'informations, voir la <Link to="/tasks-notice/task-detail-notice">notice des détails de tâche</Link>.</span> }
            ]}
            nextSteps="Utilisez les fonctionnalités de la page pour améliorer la gestion des tâches associées à différentes affaires."
            images={[{ src: tasksPageImage, alt: 'Vue de la Page Tâches', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default TaskspageNotice;
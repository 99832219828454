import React from 'react';
import NoticeComponent from '../../../NoticeComponent/NoticeComponent';
import taskimg from "../../../assets/images/tasks-to-do-image.png";

const TasksToDoNotice = () => {
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, 
            { title: 'Notice de la Page d\'Accueil', url: '/homepage-notice' },
            { title: 'Dashboard Home', url: '/homepage-notice/dashboard-home' },
            { title: 'Notice des Tâches à Faire', url: '/homepage-notice/dashboard-home/tasks-to-do-notice' }]}
            title="Notice des Tâches à Faire"
            objective="Fournir une vue d'ensemble des tâches non commencées organisées par affaire pour faciliter la gestion et le suivi."
            considerations={[
                "Cette section est essentielle pour les gestionnaires de projet et les équipes afin de surveiller et de commencer les tâches planifiées."
            ]}
            screenDescription="Liste toutes les tâches non commencées, divisées par affaire, avec la possibilité de filtrer les tâches par nom d'affaire pour une recherche plus rapide."
            steps={[
                "Utilisez le champ de filtrage en haut pour rechercher une affaire spécifique et voir ses tâches à faire.",
                "Chaque tâche listée inclut le nom de l'affaire, le nom de la tâche, une brève description, et un indicateur d'état 'Non Commencé'.",
                "Cliquez sur le bouton 'État: Non Commencé' pour accéder à des options supplémentaires, comme démarrer la tâche ou voir des détails supplémentaires."
            ]}
            nextSteps="Assurez-vous de vérifier régulièrement cette section pour mettre à jour l'état des tâches et maintenir le flux de travail de l'entreprise."
            images={[{ src: taskimg, alt: 'Liste des Tâches à Faire', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default TasksToDoNotice;
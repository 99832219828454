import React from 'react';

import newAffairFormImage from "../../assets/images/newAffairForm.png"; 
import NoticeComponent from '../../NoticeComponent/NoticeComponent';

const NewAffairNotice = () => {

    //data index 12
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, 
            { title: 'Notice des Affaires', url: '/affaires-notice' },
            { title: 'Notice de Création d\'une Affaire', url: '/affaires-notice/new-affair-notice' }]}
            title="Notice de Création d'une Affaire"
            objective="Expliquer comment remplir le formulaire pour créer une nouvelle affaire, y compris l'ajout d'informations détaillées et d'images."
            considerations={[
                "Le formulaire doit être rempli avec soin pour assurer que toutes les informations relatives à la nouvelle affaire soient correctes.",
                "La zone indique la région de travail et est importante pour la planification des feuilles d'heures."
            ]}
            screenDescription="Ce formulaire permet de saisir les détails nécessaires pour la création d'une nouvelle affaire. Il inclut des champs pour le nom, la description, l'adresse du chantier, la zone de travail, l'ajout d'une image, et la désignation de la personne responsable sur place."
            steps={[
                "Entrer le nom de l'affaire et le nom du client.",
                "Saisir la référence de l'affaire et une description détaillée.",
                "Remplir l'adresse du chantier, y compris le code postal et la ville.",
                "Sélectionner la zone correspondante de 1 à 5, qui aide à organiser les feuilles d'heures par la suite.",
                "Charger une image représentative de l'affaire en utilisant le bouton 'Choisir un fichier'.",
                "Indiquer le nom de la personne qui s'occupe du chantier sur place.",
                "Valider le formulaire en cliquant sur 'Valider'."
            ]}
            nextSteps="Vérifiez toutes les informations avant de soumettre le formulaire pour éviter les erreurs dans les données de l'affaire."
            images={[{ src: newAffairFormImage, alt: 'Formulaire de Création d\'une Affaire', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default NewAffairNotice;

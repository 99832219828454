import React from 'react';

import { Link } from 'react-router-dom';
import homeDashboardImage from "../../assets/images/homeDashboard.png";
import NoticeComponent from '../../NoticeComponent/NoticeComponent';

const DashboardHomePage = () => {
    const path = [
        { title: 'Accueil', url: '/' },
        { title: 'Notice de la Page d\'Accueil', url: '/homepage-notice' },
        { title: 'Dashboard Home', url: '/homepage-notice/dashboard-home' }
    ];

    const title = "Dashboard de la Page d'Accueil";
    const objective = "Gérer et visualiser les tâches et documents relatifs aux affaires du jour.";
    const considerations = [
        "Naviguez entre les jours pour voir les tâches et les documents spécifiques à chaque date.",
        { content: <span>Vérifiez les <Link to="/homepage-notice/dashboard-home/expired-documents-notice">documents expirés</Link> régulièrement pour rester à jour.</span> },
        { content: <span>Gérez les <Link to="/homepage-notice/dashboard-home/tasks-to-do-notice">tâches à faire</Link> pour optimiser la productivité.</span> },
        { content: <span>Découvrez le planning chantier du jour sélectionné  <Link to="/homepage-notice/dashboard-home/onglet-planning-notice">chantiers</Link> actifs.</span> }
    ];
    const screenDescription = "Le Dashboard permet une vue d'ensemble des opérations quotidiennes et des documents importants, avec des outils pour filtrer et chercher des informations spécifiques.";
    const steps = [
        "Utiliser les flèches à gauche et à droite pour changer de date.",
        "Cliquer sur les onglets pour filtrer les informations par type.",
        "Accéder aux sous-pages pour des détails supplémentaires et des fonctionnalités avancées."
    ];
    const nextSteps = "Explorez chaque onglet pour mieux comprendre et gérer vos responsabilités et celles de votre équipe.";

    return (
        <NoticeComponent
            path={path}
            title={title}
            objective={objective}
            considerations={considerations}
            screenDescription={screenDescription}
            steps={steps}
            nextSteps={nextSteps}
            images={[{ src: homeDashboardImage, alt: 'Dashboard de la home', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default DashboardHomePage;
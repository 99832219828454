import React from 'react';

import searchBarImage from "../../assets/images/searchBar.png"; // Assurez-vous que l'image est disponible
import NoticeComponent from '../../NoticeComponent/NoticeComponent';

const SearchBarNotice = () => {

    //data index 16
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, 
            { title: 'Notice de la Page d\'Accueil', url: '/homepage-notice' },
            { title: 'Notice de la Barre de Recherche', url: '/homepage-notice/search-bar-notice' }]}
            title="Notice de la Barre de Recherche d'Affaires"
            objective="Expliquer comment utiliser la barre de recherche pour trouver et accéder rapidement aux affaires."
            considerations={[
                "La barre de recherche permet de trouver rapidement une affaire spécifique par son nom.",
                "Une liste déroulante peut également être utilisée pour parcourir et sélectionner directement une affaire."
            ]}
            screenDescription="Cette fonctionnalité permet aux utilisateurs de taper le nom d'une affaire pour la rechercher ou de sélectionner une affaire directement depuis une liste déroulante, facilitant ainsi l'accès rapide au dashboard de l'affaire."
            steps={[
                "Tapez le nom de l'affaire dans la barre de recherche pour filtrer les résultats.",
                "Sélectionnez l'affaire souhaitée dans les suggestions ou utilisez la liste déroulante pour choisir une affaire.",
                "Cliquez sur l'affaire pour être redirigé immédiatement vers son Dashboard."
            ]}
            nextSteps="Utilisez cette fonctionnalité pour naviguer efficacement entre différentes affaires et améliorer votre gestion du temps."
            images={[{ src: searchBarImage, alt: 'Barre de Recherche d\'Affaires', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default SearchBarNotice;
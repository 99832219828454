import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Fuse from 'fuse.js';
import data from '../../data/data.json';
import logo from '../assets/images/logoVision.JPG';
import './TopBar.scss';

const TopBar = () => {
    const [term, setTerm] = useState('');
    const [results, setResults] = useState([]);

    useEffect(() => {
        if (term) {
            // Configurer Fuse.js pour la recherche d'URL
            const fuse = new Fuse(data, {
                keys: ['title', 'content'],
                includeScore: true,
                ignoreLocation: true, // Permet de rechercher n'importe où dans la chaîne
                threshold: 0.3,       // Ajuste la sensibilité de la recherche
                minMatchCharLength: 1 // Longueur minimale du caractère de correspondance
            });

            const result = fuse.search(term);
            setResults(result.map(r => r.item));
        } else {
            setResults([]);
        }
    }, [term]);

    const handleSearchChange = (e) => {
        const cleanedInput = e.target.value.trim().replace(/^https?:\/\//, ''); // Nettoie l'URL de http:// et https://
        setTerm(cleanedInput);
    };

    return (
        <div className="topBar">
            <div className="logoContainer">
                <Link to="/">
                    <img src={logo} alt="Logo" />
                </Link>
            </div>
            <div className="searchContainer">
                <input
                    type="text"
                    value={term}
                    onChange={handleSearchChange}
                    placeholder="Recherche..."
                    className="searchInput"
                />
                {results.length > 0 && (
                    <ul className="searchResults">
                        {results.map((item, index) => (
                            <li key={index}>
                                <Link to={item.url}>
                                    {item.title} - {item.content.substring(0, 100)}...
                                </Link>
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default TopBar;

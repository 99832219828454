import React from 'react';

import homePageImage from "../assets/images/home.png"; // Import the image
import { Link } from 'react-router-dom';
import NoticeComponent from '../NoticeComponent/NoticeComponent';

const HomePageNotice = () => {
    const considerations = [
        "Les utilisateurs doivent pouvoir accéder facilement aux informations les plus importantes dès la page d'accueil."
    ];

    const steps = [
        { content: <span>Utiliser la <Link to="/homepage-notice/search-bar-notice">barre de recherche</Link> pour rechercher et sélectionner rapidement une affaire.</span> },
        "Observer le dashboard à onglets pour un aperçu rapide des différentes informations.",
        // side bar notice
        { content: <span>Pour naviguer entre les différentes sections du SaaS, utiliser la <Link to="/homepage-notice/sidebar-notice">Sidebar</Link>, décrite en détail ici.</span> },
        // top bar notice
        { content: <span>Accéder aux fonctionnalités principales via la <Link to="/homepage-notice/top-bar-notice">Top Bar</Link>, expliquée en détail ici.</span>},
    ];

    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, { title: 'Notice de la Page d\'Accueil', url: '/homepage-notice' }]}
            title="Notice de la Page d'Accueil"
            objective="Permettre aux utilisateurs de trouver rapidement une affaire et d'afficher un aperçu des informations clés via un dashboard à onglets."
            considerations={considerations}
            screenDescription="La page d'accueil est divisée en deux parties principales: une barre de recherche pour filtrer les affaires rapidement et un dashboard qui offre un aperçu immédiat."
            steps={steps}
            nextSteps={<span>Pour une exploration détaillée de chaque élément du dashboard, une <Link to="/homepage-notice/dashboard-home">notice dashboard home.</Link> </span>}
            images={[{ src: homePageImage, alt: 'Aperçu de la Page d\'Accueil', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default HomePageNotice;
import React from 'react';

import documentsSectionImage from "../../assets/images/documentsSection.png"; 
import NoticeComponent from '../../NoticeComponent/NoticeComponent';

const DocumentsSectionNotice = () => {

//data index 15
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, 
            { title: 'Notice des Affaires', url: '/affaires-notice' },
            { title: 'Notice du Dashboard de l\'Affaire', url: '/affaires-notice/affair-dashboard-notice' },
            { title: 'Notice de la Section Documents', url: '/affaires-notice/affair-dashboard-notice/documents-section-notice' }]}
            title="Notice de la Section 'Documents'"
            objective="Expliquer la gestion des documents au sein de l'affaire, y compris la création de dossiers, l'ajout de fichiers et le suivi de leur validité via un code couleur."
            considerations={[
                "Il est crucial de bien organiser les documents pour faciliter l'accès et la gestion des informations importantes de chaque affaire.",
                "Le système de codage couleur aide à identifier rapidement le statut de validité de chaque document."
            ]}
            screenDescription="Cette section permet aux utilisateurs de créer des dossiers, d'y ajouter des fichiers, et de visualiser le statut de validité des documents grâce à un système de codage couleur."
            steps={[
                "Cliquer sur 'Créer un dossier' pour organiser les documents par catégorie ou par besoin spécifique.",
                "Ajouter des fichiers aux dossiers en cliquant sur 'Ajouter un fichier'. Les formats supportés incluent PDF, PNG, JPEG.",
                "Observer le code couleur des fichiers pour déterminer leur statut :",
                "   - Couleur classique (gris) pour les documents sans date de fin spécifique.",
                "   - Orange pour les documents dont la date de fin est proche (expire dans plus de 1 semaine).",
                "   - Rouge pour les documents dont la date de fin a expiré."
            ]}
            nextSteps="Assurez-vous de mettre à jour régulièrement les documents pour maintenir leur pertinence et leur précision, en particulier ceux avec des dates de fin."
            images={[{ src: documentsSectionImage, alt: 'Section Documents', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default DocumentsSectionNotice;

import React from 'react';

import planningPageImage from "../assets/images/planningPage.png";
import NoticeComponent from '../NoticeComponent/NoticeComponent';

const PlanningEntreprisePageNotice = () => {

    //data index 9
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, { title: 'Notice du Planning Entreprise', url: '/planning-entreprise-notice' }]}
            title="Notice de la Page 'Planning Entreprise'"
            objective="Expliquer la navigation dans le planning des tâches de chantier et d'observation, l'ajout de nouvelles tâches et la gestion des filtres."
            considerations={[
                "La page permet de visualiser et de gérer les tâches planifiées à travers différents filtres tels que le mois, la semaine, l'affaire ou le jour.",
                "Les actions sont facilitées par des boutons pour ajouter et réinitialiser les tâches ainsi que des liens directs pour la modification et le suivi détaillé des affaires."
            ]}
            screenDescription="Cette page regroupe toutes les tâches 'chantier' et 'observations' de l'ensemble des affaires enregistrées dans le logiciel. Elle offre des fonctionnalités avancées de filtration et d'ajout de tâches directement depuis le planning."
            steps={[
                "Utiliser les menus déroulants pour filtrer les tâches par mois, affaire, semaine ou jour.",
                "Cliquer sur 'Ajouter une Tache / Observation' pour créer une nouvelle tâche directement dans le planning.",
                "Utiliser le bouton 'Réinitialiser les filtres' pour effacer tous les filtres appliqués et revenir à la vue initiale.",
                { content: <span>Interagir avec une tâche spécifique pour modifier, aller à l'affaire, ou afficher le planning détaillé de l'affaire via un menu contextuel qui apparaît au clic sur une ligne.</span> }
            ]}
            nextSteps="Assurez-vous de bien comprendre le fonctionnement des filtres pour optimiser la gestion du planning des tâches et observations."
            images={[{ src: planningPageImage, alt: 'Vue de la Page Planning Entreprise', style: { width: '100%', height: 'auto' } }]}
        />
    );
};
export default PlanningEntreprisePageNotice
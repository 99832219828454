import React from 'react';

import assistanceChatImage from "../assets/images/assistanceChat.png";
import NoticeComponent from '../NoticeComponent/NoticeComponent';

const AssistanceChatNotice = () => {

    //data index 11
    return (
        <NoticeComponent
            path={[
                { title: 'Accueil', url: '/' }, 
                { title: 'Notice du Chat d\'Assistance', url: '/assistance-chat-notice' }
            ]}
            title="Notice du Chat d'Assistance"
            objective="Expliquer comment interagir avec l'équipe de support via le chat d'assistance pour les problèmes ou feedbacks."
            considerations={[
                "Le chat permet une communication directe avec l'équipe de développement pour toute assistance ou feedback.",
                "Les réponses peuvent ne pas être immédiates, donc une certaine attente peut être nécessaire."
            ]}
            screenDescription="Cette page offre un chat en direct avec les développeurs pour résoudre les problèmes ou pour fournir des feedbacks sur l'application. Le système fonctionne comme un chat classique."
            steps={[
                "Ouvrir la fenêtre de chat en cliquant sur l'icône de chat en bas à droite de l'écran.",
                "Entrer votre message dans le champ prévu à cet effet et envoyer.",
                "Attendre la réponse de l'équipe de développement. Le temps de réponse peut varier selon la disponibilité des agents de support.",
                "Les messages sont classés chronologiquement avec les plus récents en bas de la fenêtre de chat."
            ]}
            nextSteps="Utilisez régulièrement cette fonction pour communiquer efficacement tout problème technique ou suggestion d'amélioration."
            images={[{ src: assistanceChatImage, alt: 'Chat d\'Assistance', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default AssistanceChatNotice;

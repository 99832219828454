import React from 'react';

import notificationsPageImage from "../assets/images/notificationsPage.png"; 
import NoticeComponent from '../NoticeComponent/NoticeComponent';

const NotificationsPageNotice = () => {

    //data index 8
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, { title: 'Notice des Notifications', url: '/notifications-notice' }]}
            title="Notice de la Page 'Notifications'"
            objective="Expliquer l'utilisation du filtre de date pour visualiser les documents expirants et la navigation vers des documents ou des dashboards d'affaires spécifiques."
            considerations={[
                "Seuls les documents avec une date d'expiration proche et un statut autre qu'archivé sont affichés.",
                "Utiliser le calendrier pour sélectionner une date spécifique et filtrer les notifications."
            ]}
            screenDescription="Cette page permet aux utilisateurs de surveiller les documents expirants par date sélectionnée. Les documents archivés ne sont pas inclus dans les résultats."
            steps={[
                "Choisir une date dans le calendrier pour voir tous les documents expirant à cette date qui ne sont pas archivés.",
                "Cliquer sur 'Ouvrir' pour accéder directement au document.",
                { content: <span>Cliquer sur le nom de l'affaire pour naviguer vers le <a href="/affaires-notice/affair-dashboard-notice">Dashboard de l'affaire</a> correspondante.</span> }
            ]}
            nextSteps="Utilisez cette fonctionnalité pour rester informé des documents nécessitant une attention avant leur expiration."
            images={[{ src: notificationsPageImage, alt: 'Vue de la Page Notifications', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default NotificationsPageNotice;

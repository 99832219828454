import React from 'react';

import messagingPageImage from "../assets/images/messagingPage.png"; 
import NoticeComponent from '../NoticeComponent/NoticeComponent';

const MessagingPageNotice = () => {
    //data index 10
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, { title: 'Notice de Messagerie', url: '/messaging-notice' }]}
            title="Notice de la Page 'Messagerie'"
            objective="Expliquer l'utilisation de la messagerie interne, la gestion des différents types de chat, et l'envoi de messages et documents."
            considerations={[
                "Les utilisateurs peuvent avoir des chats individuels ou des chats liés à des affaires spécifiques.",
                "Seuls les participants du chat peuvent voir les messages dans les chats individuels. Pour les chats d'affaires, tous ceux qui ont accès à la partie 'bureau' ou à l'application mobile peuvent voir les messages."
            ]}
            screenDescription="Cette page permet aux utilisateurs de communiquer efficacement au sein de l'entreprise en utilisant des chats individuels ou groupés par affaires. Les utilisateurs peuvent également envoyer des photos ou des documents en plus des messages textuels."
            steps={[
                "Sélectionner un chat dans la liste pour voir les messages. Les chats sont classés par ordre des derniers messages reçus.",
                "Les nouveaux messages non lus sont indiqués par une notification sur le chat correspondant.",
                "Utiliser le champ 'Votre message...' pour écrire un nouveau message ou pour attacher des photos et des documents en utilisant le bouton approprié.",
                "Les messages les plus récents et le nombre de messages non lus apparaissent sous le titre de chaque chat."
            ]}
            nextSteps="Utilisez cette fonctionnalité pour maintenir une communication fluide et documentée avec les membres de l'équipe et les parties prenantes des affaires."
            images={[{ src: messagingPageImage, alt: 'Vue de la Page Messagerie', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default MessagingPageNotice
import React from 'react';

import { Link } from 'react-router-dom';
import affairesPageImage from "../assets/images/screenhome.png"; 
import NoticeComponent from '../NoticeComponent/NoticeComponent';

const AffairesNotice = () => {
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, { title: 'Notice des Affaires', url: '/affaires-notice' }]}
            title="Notice de la Page 'Affaires'"
            objective="Fournir un aperçu détaillé des fonctionnalités de la page Affaires."
            considerations={[
                "Les utilisateurs peuvent créer, rechercher, et visualiser les détails des affaires.",
                "Les affaires sont classées par état pour une navigation facilitée.",
                "Cliquez sur une ligne d'affaire pour accéder à son dashboard spécifique."
            ]}
            screenDescription="La page 'Affaires' permet de gérer les projets ou contrats. Elle est équipée de fonctionnalités de création, de recherche, et de consultation détaillée."
            steps={[
                { content: <span>Cliquer sur <Link to="/affaires-notice/new-affair-notice">Créer une Affaire</Link> pour démarrer une nouvelle affaire.</span> },
                "Utiliser le champ de recherche pour filtrer les affaires par mots-clés.",
                { content: <span>Chaque affaire possède un bouton <button>Détails</button> à droite, permettant d'accéder à un résumé via une popup.</span> },
                { content: <span>Cliquer sur une ligne d'affaire pour naviguer vers <Link to={`/affaires-notice/affair-dashboard-notice`}>le Dashboard de l'affaire</Link> où vous pouvez gérer tous les aspects de l'affaire sélectionnée.</span> }
            ]}
            nextSteps="Utilisez les fonctionnalités de la page pour optimiser la gestion des affaires."
            images={[{ src: affairesPageImage, alt: 'Vue de la Page Affaires', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default AffairesNotice;
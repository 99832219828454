import React from 'react';
import './noticeComponent.scss';
import { Link } from 'react-router-dom';

const NoticeComponent = ({ path, title, objective, considerations, screenDescription, steps, nextSteps, images }) => {
    return (
        <div className="notice-container">
            <div className="path">
                {path.map((p, index) => (
                    <span key={index}>
                        <Link to={p.url}>{p.title.replace('Notice', 'Notice').replace('Avis', 'Notice')}</Link>
                        {index < path.length - 1 ? ' / ' : ''}
                    </span>
                ))}
            </div>
            <h1>{title.replace('Notice', 'Notice').replace('Avis', 'Notice')}</h1>
            <section>
                <h2>Objectif de la page :</h2>
                <p>{objective}</p>
            </section>
            <section>
                <h2>Choses à prendre en compte :</h2>
                <ul>
                    {considerations.map((item, index) => <li key={index}>{typeof item === 'string' ? item : item.content}</li>)}
                </ul>
            </section>
            <section>
                <h2>Description de l'écran :</h2>
                <p>{screenDescription}</p>
                {images && images.map((img, index) => (
                    <img key={index} src={img.src} alt={img.alt} style={img.style} />
                ))}
            </section>
            <section>
                <h2>Étapes pas à pas :</h2>
                <ol>
                    {steps.map((step, index) => (
                        <li key={index}>
                            {typeof step === 'string' ? step : step.content}
                        </li>
                    ))}
                </ol>
            </section>
            <section>
                <h2>Prochaines étapes :</h2>
                <p>{nextSteps}</p>
            </section>
            <div className="buttons">
                <button onClick={() => window.scrollTo(0, 0)}>Retour en haut</button>
            </div>
        </div>
    );
};

export default NoticeComponent;
import React from 'react';
import TopBar from './Components/TopBar/TopBar';

const MainLayout = ({ children }) => {
    return (
        <div>
            <TopBar />
            <div>{children}</div>
        </div>
    );
};

export default MainLayout;
import React from 'react';

import { Link } from 'react-router-dom';
import usersPageImage from "../assets/images/usersPage.png"; 
import NoticeComponent from '../NoticeComponent/NoticeComponent';

//data index 6
const UsersPageNotice = () => {

    //data index 6
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, { title: 'Notice des Utilisateurs', url: '/users-notice' }]}
            title="Notice de la Page 'Utilisateurs'"
            objective="Expliquer comment interagir avec les cartes des utilisateurs, créer de nouveaux utilisateurs, et accéder aux détails et modifications des utilisateurs existants."
            considerations={[
                "Chaque utilisateur a une carte qui peut être accédée pour plus de détails ou pour modification.",
                "Le bouton 'Créer un utilisateur' permet de créer de nouveaux comptes utilisateur."
            ]}
            screenDescription="La page 'Utilisateurs' affiche les cartes des utilisateurs déjà créés et offre des fonctionnalités pour la gestion des utilisateurs dans le SaaS."
            steps={[
                { content: <span>Cliquer sur <Link to="/users-notice/user-form-notice">Créer un utilisateur</Link> pour accéder au formulaire de création d'un nouvel utilisateur.</span> },
                "Chaque carte utilisateur possède un bouton 'Accéder' qui mène à une page détaillée où des informations supplémentaires peuvent être consultées ou modifiées.",
                { content: <span>Pour les modifications, suivre le lien sur la page de détails pour accéder aux options de modification de l'utilisateur.</span> }
            ]}
            nextSteps="Utilisez ces fonctionnalités pour gérer efficacement les utilisateurs de votre système."
            images={[{ src: usersPageImage, alt: 'Vue de la Page Utilisateurs', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default UsersPageNotice;

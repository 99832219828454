import React from 'react';
import { Link } from 'react-router-dom';
import NoticeComponent from '../../NoticeComponent/NoticeComponent';

import sidebarImage from "../../assets/images/sidebar.png";

const SidebarNotice = () => {
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, 
            { title: 'Notice de la Page d\'Accueil', url: '/homepage-notice' },
            { title: 'Notice de la Sidebar', url: '/homepage-notice/sidebar-notice' }]}
            title="Notice de la Sidebar"
            objective="Fournir une vue d'ensemble des différentes sections accessibles via la sidebar et expliquer leur fonction."
            considerations={[
                "La sidebar est conçue pour faciliter la navigation entre les différentes sections de l'application.",
                "Chaque option de la sidebar mène à une fonctionnalité spécifique de la plateforme, permettant une gestion efficace des activités et des informations."
            ]}
            screenDescription="Cette sidebar permet un accès rapide aux différentes sections de l'application, incluant les utilisateurs, les affaires, les tâches, et plus encore."
            steps={[
                "Users : Accédez à la gestion des utilisateurs, où vous pouvez créer et gérer les profils.",
                "Affaires : Dirigez-vous vers la page qui liste toutes les affaires et permet leur gestion.",
                "Tâches : Consultez et gérez les tâches liées aux affaires.",
                "Feuilles d'heures : Générez et consultez les feuilles d'heures pour le suivi du temps travaillé.",
                "Notifications : Recevez des alertes pour les documents proches de la date d'expiration ou déjà expirés.",
                "Communication : Accédez à tous les chats, y compris les discussions liées aux affaires et aux utilisateurs.",
                "Assistance : Utilisez le chat pour demander de l'aide ou envoyer des retours aux développeurs.",
                "Info : Section actuellement non disponible.",
                "Réglages : Modifiez les informations de l'entreprise comme le nom, l'adresse, etc.",
                "Déconnexion : Déconnectez-vous de l'application."
            ]}
            nextSteps="Utiliser la sidebar pour accéder rapidement aux différentes sections et améliorer l'efficacité de la navigation."
            images={[{ src: sidebarImage, alt: 'Vue de la Sidebar', style: { width: '250px', height: '500px' } }]}
        />
    );
};

export default SidebarNotice;
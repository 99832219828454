import React from 'react';

import topBarImage from "../../assets/images/topBar.png"; // Assurez-vous que l'image est disponible
import { Link } from 'react-router-dom';
import NoticeComponent from '../../NoticeComponent/NoticeComponent';

const TopBarNotice = () => {
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, 
            { title: 'Notice de la Page d\'Accueil', url: '/homepage-notice' },
            { title: 'Notice de la Top Bar', url: '/homepage-notice/top-bar-notice' }]}
            title="Notice de la Top Bar"
            objective="Expliquer les fonctions des icônes de la top bar et leur utilité pour la navigation rapide dans l'application."
            considerations={[
                "La top bar est conçue pour fournir un accès rapide aux principales sections de l'application.",
                "Chaque icône représente une fonctionnalité différente et est placée stratégiquement pour optimiser la navigation."
            ]}
            screenDescription="Cette top bar comprend plusieurs icônes qui servent de raccourcis vers les sections les plus utilisées de l'application, en plus d'afficher le nom de l'utilisateur connecté et un bouton de déconnexion."
            steps={[
                "Première icône (maison) : Cliquez ici pour toujours revenir à la page d'accueil.",
                "Deuxième icône (affaires) : Accédez directement à la page des affaires.",
                "Troisième icône (tâches) : Rendez-vous sur la page des tâches pour voir vos activités en cours.",
                "Quatrième icône (utilisateur) : Ouvre la gestion des utilisateurs.",
                "Cinquième icône (cloche) : Consultez les documents qui arrivent en fin de validités.",
                "Sixième icône (calendrier) : Accédez à votre planning vu d'ensemble.",
                "Septième icône (calendrier avec groupe) : Visitez le planning de l'entreprise.",
                "Huitième icône (chat) : Ouvre le chat pour la communication en direct.",
                "À l'extrême droite, le nom de l'utilisateur connecté est affiché avec un bouton pour se déconnecter à côté."
            ]}
            nextSteps="Utilisez ces icônes pour naviguer rapidement dans l'application et accéder aux informations nécessaires efficacement."
            images={[{ src: topBarImage, alt: 'Top Bar de l\'Application', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default TopBarNotice;
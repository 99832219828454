import React from 'react';
import pages from "../../data/pages";
import { Link } from 'react-router-dom';
import './home.scss'; 

const Home = () => {
  return (
    <div className='homeContainer'>
      <h1>Support Vision</h1>
      <ul>
        {pages.map((page, index) => (
          <li key={index}>
            <Link to={page.url}>
              {page.icon ? <page.icon style={{ marginRight: 8 }} /> : ""}
              {page.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Home; 
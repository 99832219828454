import React from 'react';

import taskPlanningImage from "../../assets/images/affairDashboard.png"; // Utilisation de la même image que pour "AffairDashboardNotice"
import NoticeComponent from '../../NoticeComponent/NoticeComponent';

const TaskPlanningNotice = () => {

    //data index 14
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, 
            { title: 'Notice des Affaires', url: '/affaires-notice' },
            { title: 'Notice du Dashboard de l\'Affaire', url: '/affaires-notice/affair-dashboard-notice' },
            { title: 'Notice du Tâche Planning', url: '/affaires-notice/affair-dashboard-notice/task-planning-notice' }]}
            title="Notice de la Page 'Tâche Planning'"
            objective="Fournir des instructions sur la gestion des tâches de chantier et bureau, ainsi que la gestion des documents expirant."
            considerations={[
                "Cette interface permet de planifier, ajouter, et gérer des tâches spécifiques à une affaire.",
                "Les tâches peuvent être de type 'chantier' ou 'observation', avec des icônes distinctes pour une identification facile."
            ]}
            screenDescription="Le 'Tâche Planning' offre des fonctionnalités pour ajouter des tâches, afficher les listes nécessaires pour le chantier et le bureau, et gérer la progression des tâches à travers un calendrier interactif."
            steps={[
                "Cliquer sur 'Ajouter une tâche' pour créer une nouvelle tâche 'chantier' ou 'observation'.",
                "Utiliser 'Afficher la liste à prendre' pour ouvrir une modale listant les éléments nécessaires pour le chantier, comme pelle, bétonnière, etc.",
                "Cliquer sur 'Afficher la liste à faire' pour voir les tâches à réaliser côté bureau, telles que la préparation de plans ou les commandes de matériel.",
                "Dans le planning, les tâches de chantier sont indiquées avec une icône de 'HandymanIcon', les observations avec 'InfoIcon', et les documents avec une date de fin de validité avec 'FolderOpenIcon'.",
                "Interagir avec les jours d'une tâche pour ouvrir un menu permettant de modifier ou décaler la tâche, ce qui peut influencer la planification des tâches suivantes.",
                "Utiliser la fonction de progression pour ajuster l'avancement des tâches de 0 à 100%."
            ]}
            nextSteps="Explorez ces outils pour améliorer la gestion et le suivi des tâches liées à vos affaires."
            images={[{ src: taskPlanningImage, alt: 'Tâche Planning', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default TaskPlanningNotice;

import RoofingIcon from '@mui/icons-material/Roofing'
import BusinessIcon from '@mui/icons-material/Business'
import HandymanIcon from '@mui/icons-material/Handyman'
import PermIdentityIcon from '@mui/icons-material/PermIdentity'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone'
import ListAltIcon from '@mui/icons-material/ListAlt'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import SupportIcon from '@mui/icons-material/Support'

const pages = [
    { title: "Page Accueil", url: "/homepage-notice", icon:RoofingIcon},
    { title: "Page Affaires", url: "/affaires-notice", icon:BusinessIcon },
    { title: "Page Taches", url: "/tasks-notice", icon:HandymanIcon },
    { title: "Page Utilisateurs", url: "/users-notice", icon:PermIdentityIcon},
    { title: "Page Notifications", url: "/notifications-notice", icon:NotificationsNoneIcon},
    { title: "Page Planning Entreprise", url: "/planning-entreprise-notice", icon:ListAltIcon},
    { title: "Page Messagerie", url: "/messaging-notice", icon:ForumOutlinedIcon },
    { title: "Assistance", url: "/assistance-chat-notice", icon:SupportIcon }
];
 
export default pages.sort((a, b) => a.title.localeCompare(b.title));
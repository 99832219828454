import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Home from './Pages/Home/Home';
import HomePageNotice from './Components/HomePageNotice/HomePageNotice';
import DashboardHomePage from './Components/HomePageNotice/DashboardHomePage/DashboardHomePage';
import MainLayout from './MainLayout';
import SidebarNotice from './Components/HomePageNotice/SidebarNotice/SidebarNotice';
import TopBarNotice from './Components/HomePageNotice/TopBarNotice/TopBarNotice';
import AffairesNotice from './Components/AffairesPageNotice/AffairesNotice';
import TaskspageNotice from './Components/TasksPageNotice/TaskspageNotice';
import TaskDetailNotice from './Components/TasksPageNotice/TaskDetailNotice/TaskDetailNotice';
import UsersPageNotice from './Components/UsersPageNotice/UsersPageNotice';
import UserFormNotice from './Components/UsersPageNotice/UserFormNotice/UserFormNotice';
import NotificationsPageNotice from './Components/NotificationsPageNotice/NotificationsPageNotice';
import PlanningEntreprisePageNotice from './Components/PlanningEntreprisePageNotice/PlanningEntreprisePageNotice';
import MessagingPageNotice from './Components/MessagingPageNotice/MessagingPageNotice';
import AssistanceChatNotice from './Components/AssistanceChatNotice/AssistanceChatNotice';
import NewAffairNotice from './Components/AffairesPageNotice/NewAffairNotice/NewAffairNotice';
import AffairDashboardNotice from './Components/AffairesPageNotice/AffairDashboardNotice/AffairDashboardNotice';
import TaskPlanningNotice from './Components/AffairesPageNotice/TaskPlanningNotice/TaskPlanningNotice';
import DocumentsSectionNotice from './Components/AffairesPageNotice/documentsSectionImage/DocumentsSectionImage';
import SearchBarNotice from './Components/HomePageNotice/SearchBarNotice/SearchBarNotice';
import ExpiredDocumentsNotice from './Components/HomePageNotice/DashboardHomePage/ExpiredDocumentsNotice/ExpiredDocumentsNotice';
import TasksToDoNotice from './Components/HomePageNotice/DashboardHomePage/TasksToDoNotice/TasksToDoNotice';
import OngletPlanningNotice from './Components/HomePageNotice/DashboardHomePage/OngletPlanningNotice/OngletPlanningNotice';


function App() {
  return (
    <Router>
      <MainLayout>
        <Routes>
          <Route path="/" element={<Home />} />
            <Route path="/homepage-notice" element={<HomePageNotice />} />
              <Route path="/homepage-notice/dashboard-home" element={<DashboardHomePage />} />
                <Route path="/homepage-notice/dashboard-home/expired-documents-notice" element={<ExpiredDocumentsNotice />} />
                <Route path="/homepage-notice/dashboard-home/tasks-to-do-notice" element={<TasksToDoNotice />} />
                <Route path="/homepage-notice/dashboard-home/onglet-planning-notice" element={<OngletPlanningNotice />} />
              <Route path="/homepage-notice/sidebar-notice" element={<SidebarNotice />} />
              <Route path="/homepage-notice/top-bar-notice" element={<TopBarNotice />} />
              <Route path="/homepage-notice/search-bar-notice" element={<SearchBarNotice />} />
            <Route path="/affaires-notice" element={<AffairesNotice />} />
              <Route path="/affaires-notice/new-affair-notice" element={<NewAffairNotice />} />
              <Route path="/affaires-notice/affair-dashboard-notice" element={<AffairDashboardNotice />} />
                <Route path="/affaires-notice/affair-dashboard-notice/task-planning-notice" element={<TaskPlanningNotice />} />
                <Route path="/affaires-notice/affair-dashboard-notice/documents-section-notice" element={<DocumentsSectionNotice />} />
            <Route path="/tasks-notice" element={<TaskspageNotice />} />
              <Route path="/tasks-notice/task-detail-notice" element={<TaskDetailNotice />} />
            <Route path="/users-notice" element={<UsersPageNotice />} />
              <Route path="/users-notice/user-form-notice" element={<UserFormNotice />} />
            <Route path="/notifications-notice" element={<NotificationsPageNotice />} />
            <Route path="/planning-entreprise-notice" element={<PlanningEntreprisePageNotice />} />
            <Route path="/messaging-notice" element={<MessagingPageNotice />} />
            <Route path="/assistance-chat-notice" element={<AssistanceChatNotice />} />
        </Routes>
      </MainLayout>
    </Router>
  );
}

export default App;
import React from 'react';
import NoticeComponent from '../../../NoticeComponent/NoticeComponent';
import expireDocument from '../../../assets/images/expired-documents-image.png';


const ExpiredDocumentsNotice = () => {

    //data index 17
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, 
            { title: 'Notice de la Page d\'Accueil', url: '/homepage-notice' },
            { title: 'Dashboard Home', url: '/homepage-notice/dashboard-home' },
            { title: 'Notice des Documents Expirés', url: '/homepage-notice/dashboard-home/expired-documents-notice' }]}
            title="Notice des Documents Expirés"
            objective="Expliquer comment identifier et accéder aux documents dont la date de validité a expiré ou est sur le point d'expirer."
            considerations={[
                "Cet onglet est crucial pour la gestion des documents réglementaires et contractuels, afin d'éviter les risques associés à l'expiration de documents importants."
            ]}
            screenDescription="Cette section affiche une liste des documents expirés ou sur le point d'expirer, avec des informations détaillées telles que le nom du document, l'affaire associée, la date d'expiration et un bouton pour ouvrir le document."
            steps={[
                "Chaque ligne liste un document avec son nom à gauche.",
                "Le nom de l'affaire associée au document est indiqué à côté du nom du document.",
                "La date d'expiration du document est affichée à droite de l'affaire.",
                "Un bouton 'Ouvrir' permet d'accéder directement au document pour le consulter ou le mettre à jour."
            ]}
            nextSteps="Vérifiez régulièrement cette section pour vous assurer que tous les documents nécessaires sont à jour et renouvelés avant leur expiration."
            images={[{ src:expireDocument , alt: 'Onglet Documents Expirés', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default ExpiredDocumentsNotice;

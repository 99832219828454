import React from 'react'
import NoticeComponent from '../../../NoticeComponent/NoticeComponent';
import ongletPlanning from "../../../assets/images/ongletPlanning.png";

const OngletPlanningNotice = () => {

    //data index 18
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' },
            { title: 'Notice de la Page d\'Accueil', url: '/homepage-notice' },
            { title: 'Dashboard Home', url: '/homepage-notice/dashboard-home' },
            { title: 'Notice du Planning', url: '/homepage-notice/dashboard-home/onglet-planning-notice' }]}
            title="Notice du Planning"
            objective="Fournir une vue d'ensemble et des détails sur les tâches planifiées pour diverses affaires, facilitant ainsi la gestion quotidienne et l'allocation des ressources."
            considerations={[
                "Cette interface est essentielle pour les gestionnaires et les équipes pour assurer le suivi des engagements et la distribution des ressources humaines."
            ]}
            screenDescription="La vue du planning présente les tâches par jour pour chaque affaire, incluant les informations telles que le nom de l'affaire, la tâche spécifique, la ville, et les employés affectés."
            steps={[
                "Naviguez entre les dates à l'aide des flèches bleues pour voir les tâches planifiées pour les différents jours.",
                "Pour chaque affaire, la tâche assignée, la localisation et les employés affectés sont listés de manière claire pour faciliter la compréhension et la gestion.",
            ]}
            nextSteps="Assurez-vous de mettre à jour régulièrement les informations du planning pour refléter tout changement dans les horaires ou les affectations de tâches."
            images={[{ src: ongletPlanning, alt: 'Vue du Planning', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default OngletPlanningNotice
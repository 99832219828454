import React from 'react';

import taskDetailImage from "../../assets/images/taskDetail.png"; 
import NoticeComponent from '../../NoticeComponent/NoticeComponent';

const TaskDetailNotice = () => {

    // data index 5
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' },
            { title: 'Notice des Tâches', url: '/tasks-notice' },
            { title: 'Notice du Détail de la Tâche', url: '/tasks-notice/task-detail-notice' }]}
            title="Notice du Détail de la Tâche 'À Faire'"
            objective="Fournir un guide sur la manière de modifier ou de compléter une tâche, mettre à jour son état, et configurer un rappel."
            considerations={[
                "Les champs doivent être remplis correctement pour assurer une mise à jour précise.",
                "Le rappel par e-mail aidera à suivre les tâches importantes à une date et heure spécifiée."
            ]}
            screenDescription="Cette interface permet de visualiser et modifier le détail d'une tâche 'à faire'. Elle comprend plusieurs champs pour mettre à jour l'information de la tâche et un champ pour configurer un rappel."
            steps={[
                "Mettre à jour les champs avec les nouvelles informations de la tâche.",
                "Sélectionner le nouvel état de la tâche dans le menu déroulant.",
                "Pour configurer un rappel, sélectionner la date et l'heure et cocher 'Heure de ra'.",
                "Cliquer sur 'VALIDER LES MODIFICATIONS' pour sauvegarder les changements et configurer le rappel."
            ]}
            nextSteps="Assurez-vous de revoir toutes les informations avant de valider les modifications pour éviter des erreurs."
            images={[{ src: taskDetailImage, alt: 'Vue du Détail de la Tâche', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default TaskDetailNotice;
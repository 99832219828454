import React from 'react';

import userFormImage from "../../assets/images/userForm.png"; // Assurez-vous que l'image est disponible
import NoticeComponent from '../../NoticeComponent/NoticeComponent';

const UserFormNotice = () => {

    //data index 7
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' }, 
            { title: 'Notice des Utilisateurs', url: '/users-notice' },
            { title: 'Notice de Gestion des Utilisateurs', url: '/users-notice/user-form-notice' }]}
            title="Notice du Formulaire de Gestion des Utilisateurs"
            objective="Expliquer comment créer ou modifier un utilisateur, gérer les rôles et comprendre les niveaux d'accès associés à chaque rôle."
            considerations={[
                "Les champs doivent être correctement remplis pour assurer la création ou la mise à jour précise du profil utilisateur.",
                "Le choix des rôles influence directement les accès de l'utilisateur aux différentes parties de l'application."
            ]}
            screenDescription="Ce formulaire permet de créer ou de modifier les détails d'un utilisateur, y compris l'upload d'une photo de profil. Il est crucial de bien comprendre la gestion des rôles pour configurer correctement les accès."
            steps={[
                "Remplir les champs du formulaire avec les informations de l'utilisateur.",
                "Uploader une photo de profil en cliquant sur 'Choisir un fichier'.",
                "Sélectionner un ou plusieurs rôles pour l'utilisateur. Les rôles définissent les accès aux différentes parties de l'application:",
                "Pour accéder à la partie 'bureau' et 'application mobile', l'utilisateur doit être 'Admin' et au moins un des postes suivants: 'Responsable', 'Maître d'Oeuvre', 'Chiffreur(euse)', 'Conducteur(ice) de Travaux', 'Administratif'.",
                "Pour accéder uniquement à l'application mobile, l'utilisateur doit être 'Admin' et 'Chef d'équipe'.",
                "'Ouvrier Terrain' ne donne aucun accès à la partie bureau ou à l'application mobile.",
                "Cliquer sur 'Valider les modifications' pour enregistrer le profil."
            ]}
            nextSteps="Vérifiez toujours les rôles attribués pour vous assurer que les utilisateurs ont les accès appropriés aux fonctionnalités de l'application."
            images={[{ src: userFormImage, alt: 'Formulaire de Gestion des Utilisateurs', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default UserFormNotice;

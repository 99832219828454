import React from 'react';
import { Link } from 'react-router-dom';
import affairDashboardImage from "../../assets/images/affairDashboard.png"; // Assurez-vous que l'image est disponible
import NoticeComponent from '../../NoticeComponent/NoticeComponent';

const AffairDashboardNotice = () => {

    //data index 13
    return (
        <NoticeComponent
            path={[{ title: 'Accueil', url: '/' },
            { title: 'Notice des Affaires', url: '/affaires-notice' },
            { title: 'Notice du Dashboard de l\'Affaire', url: '/affaires-notice/affair-dashboard-notice' }]}
            title="Notice du Dashboard de l'Affaire"
            objective="Fournir une vue d'ensemble des fonctionnalités accessibles depuis le dashboard de l'affaire, y compris la gestion des tâches, des documents, et la communication."
            considerations={[
                "Le dashboard centralise l'accès aux différents aspects d'une affaire, facilitant la gestion quotidienne et le suivi des avancements.",
                "Les boutons et les onglets permettent une navigation rapide entre les composants liés à l'affaire."
            ]}
            screenDescription="Cette page du dashboard permet de visualiser et gérer une affaire spécifique. Elle offre des onglets pour accéder aux tâches, documents, modifications de l'affaire, et autres fonctionnalités en cours de développement comme les rapports de fin de journée."
            steps={[
                
                
                { content: <span>                
                    Utiliser les onglets en haut à gauche pour naviguer entre les différents composants de l'affaire : 
                    <Link to="/affaires-notice/affair-dashboard-notice/task-planning-notice">Tâche Planning</Link>, 
                    <Link to="/affaires-notice/affair-dashboard-notice/documents-section-notice">Documents</Link>, 
                    Modification Affaire.
                    </span>   
                },
                "Cliquer sur '...' pour accéder à des options supplémentaires telles que le suivi des tâches, les résumés d'affaire, et le chat de l'affaire.",
                { content: <span> 
                    Le bouton 'Planning' redirige vers 
                    <Link to="/planning-entreprise-notice">le planning d'entreprise</Link> où toutes les tâches sont planifiées et gérées.
                    </span>  
                },
                { content:<span> 
                    Utiliser le menu déroulant pour sélectionner une autre affaire et accéder à son dashboard.
                    </span>
                },
                "Les liens pour chaque composant spécifique fournissent une navigation directe vers des notices détaillées pour chaque fonctionnalité."
            ]}
            nextSteps="Explorez les différentes fonctionnalités du dashboard pour maximiser la gestion de l'affaire."
            images={[{ src: affairDashboardImage, alt: 'Dashboard de l\'Affaire', style: { width: '100%', height: 'auto' } }]}
        />
    );
};

export default AffairDashboardNotice;
